import React from "react";
import { formatISODateToReadableString } from "../../utils/util-function";

function BlogCard({
  title,
  description,
  totalToReadArticle,
  typeOfContent,
  articleId,
  createdAt,
  handleClickToBlog,
}) {
  const isDark = localStorage.getItem("darkMode") === "true";
  return (
    <div
      onClick={() => {
        handleClickToBlog(articleId);
      }}
      className="md:w-[30rem] w-[22.8rem] my-2 rounded-lg  hover:cursor-pointer transition-transform duration-200 hover:translate-x-10 "
      style={{
        border: isDark
          ? "1px solid rgba(255,255,255,0.3)"
          : "1px solid rgba(0, 0, 0, 0.3)",
      }}
    >
      <div className="px-5 py-3 fade-in ">
        <div className="flex justify-between items-center">
          <div className="font-semibold text-lg mb-1">{title}</div>
          <div
            className="px-1 py-0.5"
            style={{
              backgroundColor: isDark ? "#fff" : "#000",
              color: isDark ? "#000" : "#fff",
              borderRadius: "0.25rem",
            }}
          >
            <p className="font-normal text-sm">{typeOfContent}</p>
          </div>
        </div>

        <p className=" text-base font-light">{description}</p>
        <p className=" opacity-50 mt-1 text-sm font-light">
          {formatISODateToReadableString(createdAt)} - {totalToReadArticle} min
          read
        </p>
      </div>
    </div>
  );
}

export default BlogCard;
